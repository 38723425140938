import React from 'react';
import { graphql } from 'gatsby';
import CaseLayout from '../components/CaseLayout';

const TechTalent = ({ data: { datoCmsTechTalent, allDatoCmsTechTalent } }) => {
  const relatedPosts = allDatoCmsTechTalent.edges.map((techTalent) => techTalent.node);
  return <CaseLayout {...datoCmsTechTalent} relatedPosts={relatedPosts} />;
};

export default TechTalent;

export const techTalentQuery = graphql`
  query ($id: String) {
    datoCmsTechTalent(id: { eq: $id }) {
      title
      date(formatString: "DD.MM.YYYY")
      dateTime: date(formatString: "YYYY-MM-DD")
      shortIntroNode {
        childMarkdownRemark {
          html
        }
      }
      slug
      model {
        name
      }
      contentNode {
        childMarkdownRemark {
          html
          fields {
            readingTime {
              text
            }
          }
        }
      }
      videoEmbedUrl
      seo {
        title
        description
        image {
          url
        }
      }
      expert {
        name
        yearStartedInTech
        jobPosition
        company {
          name
          website
          jobOpenings
        }
        homeCountry {
          country
        }
        techTalentImage {
          gatsbyImageData(placeholder: BLURRED, layout: CONSTRAINED, width: 800)
        }
        meta {
          updatedAt(formatString: "DD.MM.YYYY")
        }
      }
    }
    allDatoCmsTechTalent(
      limit: 7
      sort: { fields: meta___firstPublishedAt, order: DESC }
      filter: { id: { ne: $id } }
    ) {
      edges {
        node {
          title
          slug
          ribbonCaption
          ribbonImage: expert {
            techTalentImage {
              gatsbyImageData(placeholder: BLURRED, layout: CONSTRAINED, width: 384)
              alt
            }
          }
          contentNode {
            childMarkdownRemark {
              excerpt
              html
            }
          }
          model {
            name
          }
          date(formatString: "DD.MM.YYYY")
          dateTime: date(formatString: "YYYY.MM.DD")
        }
      }
    }
  }
`;
